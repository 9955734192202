/* You can add global styles to this file, and also import other style files */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "assets/fonts/stylesheet.css";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

html,
body {
  counter-reset: my-sec-counter;
}

html {
  overflow: scroll !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  font-family: "Raleway" !important;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  font-family: "Raleway" !important;
  overflow-wrap: break-word;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span.li,
a,
.mat-mdc-list-item .mdc-list-item__primary-text,
label,
input {
  font-family: "Raleway" !important;
}

.mat-menu-panel {
  width: 400px;
}

body {
  font-family: "Raleway";
}

.material-icons {
  font-family: "Material Icons" !important;
}

p {
  white-space: break-spaces;
}

.mat-tab-label-container {
  display: flex;
  flex-grow: 1;
  z-index: 1;
  overflow-x: scroll !important;
}

.right-nav {
  float: right;
  text-align: right;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  img {
    cursor: pointer;
    color: gray;
    margin-right: 20px;
    margin-bottom: 0px;
    border-radius: 50%;

    width: 28px;
    height: 28px;
    border: 2px solid transparent !important;

    &:focus,
    &.active,
    &:target {
      outline: none !important;
      border: 3px solid #5ea1d8 !important;
    }

    &.mat-accent {
      border: 3px solid #5ea1d8 !important;
    }
  }

  .mat-icon {
    margin-right: 15px;
    font-size: 30px;
    cursor: pointer;
    font-size: 30px;
    // color: #212427 !important;
    display: flex;
    align-items: center;
    text-shadow: 0px 2px 2px rgba(186, 183, 183, 0.4);

    &:hover {
      margin-top: -10px;

      transition: all 0.3s linear;
    }

    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus,
    &:target {
      color: #5ea1d8 !important;
      outline: none;
    }

    &:last-of-type {
      margin-right: 0px !important;
      color: grey;

      &:link,
      &:visited,
      &:hover,
      &:active,
      &:focus,
      &:target {
        color: #5ea1d8;
        outline: none;
      }
    }

    & ~ span {
      margin-right: 15px !important;
      display: flex;
      align-items: center;
      font-size: 26px !important;
      color: grey;
      text-shadow: 0px 2px 2px rgba(186, 183, 183, 0.4);

      &:hover {
        margin-top: -10px;
        color: #5ea1d8 !important;
        transition: all 0.3s linear;
      }

      &:active,
      &:link,
      &:focus,
      &:target,
      &:visited {
        color: #5ea1d8 !important;
        outline: none;
      }
    }
  }

  img {
    &:hover {
      margin-top: -10px;
      transition: all 0.3s linear;
    }
  }
}

.right-nav .mat-icon[aria-controls="mat-menu-panel-1"] {
  color: #5ea1d8 !important;
}

p {
  // font-family: "Raleway";
  font-weight: 500 !important;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.54) !important;
  color: #212427 !important;
  word-break: break-word;

  line-height: 16px;
  margin: 10px 0px !important;
  line-height: 26px !important;
}

p.lf13 {
  line-height: 18px !important;
  white-space: break-spaces;
}

h2 {
  color: rgba(0, 0, 0, 0.54) !important;
  color: #212427 !important;
  font-size: 2rem !important;

  span {
    color: rgba(0, 0, 0, 0.54) !important;
    color: #212427 !important;
    font-size: 2rem !important;
  }
}

span {
  font-weight: 500 !important;
}

h6 {
  color: rgba(0, 0, 0, 0.54) !important;
  color: #212427 !important;
}

h3 {
  font-family: "Raleway";
  margin-bottom: 20px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: rgba(0, 0, 0, 0.54) !important;
  color: #212427 !important;
}

button.common {
  padding: 3px 10px !important;
  background: #5ea1d8 !important;
  border: 0px !important;
  color: #fff !important;
  font-size: 15px !important;
  border-radius: 5px !important;
}

h3 {
  a {
    font-family: "Raleway";
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.54) !important;
    color: #212427 !important;
    background: transparent !important;
    font-size: 30px !important;
    padding: 0px !important;
    margin-bottom: 25px;
    text-shadow: 2px 2px #ebebeb;
    font-weight: bold !important;
    position: relative;
    cursor: pointer;

    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background: rgba(0, 0, 0, 0.54);
      background: #212427;
      position: absolute;
      bottom: -10px;
      left: 0px;
    }
  }

  &.flow-by-category {
    a {
      pointer-events: none;

      &:after {
        display: none;
      }
    }
  }
}

.mat-sidenav-content {
  background: #fefefe !important;
  background: #efeeee !important;
  min-height: 89vh !important;

  padding: 2rem 3rem;

  &:empty {
    display: none;
  }
}

.div1 {
  display: none !important;
}

/* noti-menu design starts here for desktop*/
.mat-menu-content:not(:empty) {
  padding: 0px !important;
}

.noti-menu {
  display: flex !important;
  max-width: 430px !important;
  width: 100%;
  padding: 0px 0px;
  overflow-y: scroll !important;
  max-height: 400px !important;
  background: #fff !important;

  & > div {
    display: flex;
    flex-wrap: wrap;

    & > span {
      width: 100%;
      border-bottom: 1px solid #ddd;
      min-height: 35px;
      margin-top: 6px;
      margin-bottom: 0px;
      padding: 10px 0px;
      align-items: center;

      &:hover {
        background: transparent !important;
      }
    }
  }

  bold {
    font-weight: bold;
    padding-right: 5px;
    display: contents;
  }

  img {
    margin-right: 10px;
    margin-top: 0px !important;
  }

  span {
    display: flex;
    word-break: break-word;
    height: auto !important;
    line-height: 20px;
    align-items: flex-start;
    font-family: "Raleway" !important;

    &.text-wrap {
      flex: 1;
    }

    img {
      margin-left: 10px;
    }
  }
}

@media screen and (max-width: 992px) {
  .fixed-header {
    padding: 0px 15px !important;

    > div {
      flex: auto !important;
      display: contents;
    }

    .mat-icon-button {
      margin: 0px !important;
      margin-right: 0px !important;
    }

    a {
      flex: 1;
      text-align: center !important;
    }
  }

  h3 a {
    font-size: 20px !important;
  }

  .mat-drawer-content {
    padding: 0px 15px;
    padding-bottom: 50px !important;
  }

  .owl-carousel .owl-nav {
    display: none;
  }

  /*footer ico design starts here*/
  .div1 {
    display: block !important;
  }

  .foot-ico {
    width: 100%;
    margin: 0px auto;
    display: flex;
    align-items: flex-start !important;
    height: 100%;
    border-top: 1px solid lightgray;
    padding-top: 5px !important;

    ul {
      display: flex;
      list-style: none;
      align-items: center;
      justify-content: center;
      margin: 0px;
      width: 100%;
      margin: 0px auto;
      padding: 0px;

      li {
        margin: 0px;
        width: 25px;
        flex: 1;
        display: flex;
        justify-content: center;

        mat-menu {
          width: 0px !important;
        }

        span {
          text-shadow: 0px 2px 2px rgb(186 183 183 / 40%);
          font-size: 25px;
          color: #212427;

          &[aria-expanded="true"] {
            color: #5ea1d8 !important;
          }
        }

        img {
          margin: 0px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
      }
    }
  }

  .noti-menu {
    position: absolute;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 767px) {
  .fixed-header {
    padding: 0px 15px !important;

    > div {
      // text-align: right;
      flex: auto !important;
      display: contents;
    }

    .mat-icon-button {
      margin: 0px !important;
      margin-right: 0px !important;
    }

    a {
      flex: 1;
      text-align: center;
    }
  }

  h3 a {
    font-size: 20px !important;
  }
}

/*sample home page style here*/

.user-pro {
  .owl-carousel {
    .owl-stage {
      display: flex;
    }

    .owl-item {
      border: 1px solid #dddddd;
      background: #fff;
      overflow: hidden;
      border-radius: 5px;

      .item {
        p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 35px !important;
          white-space: normal;
          word-break: break-word;
          display: flex;
          align-items: flex-start;
          margin-top: 0px !important;
        }
      }

      .with-icon {
        width: 100%;
        font-family: "Raleway";
        display: flex;
        align-items: center;

        > div {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          margin-right: 5px;
        }

        img {
          object-fit: cover;
          height: 40px !important;
          border: 0px;
        }
      }

      img {
        width: 100% !important;
        height: 150px !important;
        border-radius: 0px !important;
        object-fit: cover;
        border: 1px solid #dddddd;
      }

      &:nth-child(6n) ~ :last-child {
        background: transparent;
        width: auto;
        height: auto;
        border: none;

        .item {
          width: auto !important;
          background: transparent;
          height: 100%;
          display: flex;
          align-items: center;
          justify-self: center;

          & > div {
            width: 100%;
            height: 100%;
          }

          .zoom {
            display: flex;
            flex-wrap: wrap;
            color: #000;
            padding: 5px 10px;
            align-items: center;
            justify-content: center;
            margin: 0px !important;
            background: #fff;
            padding: 5px 10px;
            border-radius: 5px;
            height: 100%;
            transition: none !important;
            font-family: "Raleway";

            & span:last-of-type {
              margin: 0px 0px 0px 5px !important;
              width: 100%;
              display: block;
              text-align: center;
              font-size: 29px;
              line-height: 0px;
            }

            &:hover {
              transition: none;
              transform: none;
            }
          }
        }
      }
    }

    .owl-nav {
      position: absolute;
      margin-top: 0px;
      width: 100%;
      top: calc(50% - 35px);
      left: 50%;
      transform: translate(-50%, -50%);

      button.owl-prev {
        left: -45px;
        position: absolute;
        background: #fafafa !important;
        box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%),
          0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;

        span {
          font-size: 35px;
          line-height: 0px;
          display: flex;
          align-items: center;
          margin-top: -8px;
        }

        &:hover {
          color: rgba(0, 0, 0, 0.87);
        }

        &.disabled {
          display: none;
        }
      }

      button.owl-next {
        right: -45px;
        position: absolute;
        background: #fafafa !important;
        box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%),
          0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;

        span {
          font-size: 35px;
          line-height: 0px;
          display: flex;
          align-items: center;
          margin-top: -8px;
        }

        &:hover {
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
  }

  .row {
    justify-content: left;
  }

  .ng-star-inserted {
    &:nth-child(6n + 6) {
      .item {
        margin-right: 0px;
      }
    }
  }

  .item {
    overflow: hidden;
    background: #fff;
    border: 0px;
    position: relative;
    margin: 0px auto;

    & > div {
      margin: 10px;
    }

    // p {
    //   display: -webkit-box;
    //   -webkit-line-clamp: 2;
    //   -webkit-box-orient: vertical;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   height: 33px !important;
    //   white-space: normal;
    //   word-break: break-word;
    //   display: flex;
    //   align-items: center;
    // }
    .with-icon {
      width: 100%;
      font-family: "Raleway";
      display: flex;
      align-items: center;

      > div {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin-right: 5px;

        img {
          margin-bottom: 0px !important;
        }

        & + p {
          margin-top: 0px !important;
        }
      }

      img {
        object-fit: cover;
        height: 40px !important;
        border: 0px;
      }

      p {
        align-items: center !important;
        margin-bottom: 0px !important;
      }
    }

    .zoom {
      margin: 5px;
      width: calc(100% - 10px);
      background-color: transparent;
    }

    img {
      height: 150px;
      object-fit: cover;
      width: 100%;
      border: 1px solid #dddddd;
    }
  }
}

.noti-menu::-webkit-scrollbar {
  width: 4px;
  height: 100px;
}

.noti-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.noti-menu::-webkit-scrollbar-thumb {
  background-color: #5ea1d8;
  outline: 1px solid #5ea1d8;
}

/*user profile page tab design start here*/
.gridgroup-wrap {
  .tablist {
    padding: 5px;
  }

  .mat-tab-labels {
    font-family: "Raleway";
    font-weight: 500;
    cursor: pointer;
    font-size: 17px;
    color: #5ea1d8;
    background: #5ea1d8;
    background: #fff;
    box-shadow: -2px 2px 7px rgb(200 193 193 / 50%);
  }

  .mat-tab-label-container {
    display: contents;
  }

  .mat-grid-tile .mat-figure {
    padding-right: 10px;
  }

  .mat-tab-label-active {
    color: #5ea1d8;
    position: relative;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #5ea1d8;
      position: absolute;
      bottom: -2px;
      transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
    }
  }

  .mat-tab-label {
    background: #fff;
    opacity: 1;
  }

  span {
    font-family: "Raleway";
    font-weight: 600;
  }

  .mat-badge-content {
    background-color: #5ea1d8 !important;
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #5ea1d8;
  }
}

/*followerd user profile added right*/
.addrgt {
  height: 54px !important;

  .mat-grid-tile:first-child {
    display: none;
  }

  .mat-grid-tile {
    display: block;
    position: absolute;
    overflow: hidden;
    max-width: 100% !important;
    width: 100% !important;
    // background: yellow;
    left: 0px !important;
    height: 54px !important;
  }

  .textcolor {
    padding: 5px 10px;
    background: #fff;
    box-shadow: -2px 2px 7px rgb(200 193 193 / 50%);
    font-family: "Raleway";
    font-weight: 500;
    font-size: 13px !important;
    cursor: pointer;
    font-size: 17px;
    color: #000;
    color: #5ea1d8 !important;
    text-decoration: none !important;
    border: 1px solid #5ea1d8;
  }

  .mat-figure div {
    display: flex !important;
    justify-content: space-between;

    a {
      margin: 0px !important;
    }
  }
}

@media (max-width: 1024px) {
  .addrgt .mat-figure div {
    justify-content: left !important;
  }
}

/*user profile tab responsive*/
@media (max-width: 767px) {
  .gridgroup-wrap .mat-tab-labels {
    display: flex;

    & > div {
      flex: 1;
      padding: 0px;
    }
  }
}

@media (max-width: 992px) {
  .flowdraft-wrap {
    .mat-mdc-tab-body-wrapper {
      padding: 0px 10px;
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 993px) {
  .owl-carousel .owl-nav button.owl-next {
    right: -45px;
  }

  .owl-carousel .owl-nav button.owl-prev {
    left: -45px;
  }
}

/*view profile page starts here*/
.viewflow-step {
  .owl-carousel .owl-stage {
    width: 100% !important;
    display: block !important;

    .owl-item {
      width: 100% !important;
      border: 0px;
      border-radius: 0px;

      .item {
        display: flex;

        p {
          font-family: "Raleway";
          margin: 0px;
          padding: 0px 20px;
        }

        // height:400px;

        & > div {
          flex: 1;

          img {
            height: auto !important;
            -o-object-fit: cover;
            object-fit: cover;
            max-width: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px auto;
            text-align: center;
            /* position: absolute; */
            left: 0px;
            right: 0px;
            border: 0px;
          }

          &:last-child {
            padding: 20px;
          }
        }
      }
    }

    .owl-nav {
      position: absolute;
      margin-top: 0px;
      width: 100%;
      top: calc(50% - 35px);
      left: 50%;
      transform: translate(-50%, -50%);

      button.owl-prev {
        left: -55px;
        position: absolute;
        background: #fafafa !important;
        box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%),
          0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        display: block;

        span {
          font-size: 35px;
          line-height: 0px;
          display: flex;
          align-items: center;
          margin-top: -8px;
        }

        &:hover {
          color: rgba(0, 0, 0, 0.87);
        }

        &.disabled {
          display: block !important;
        }
      }

      button.owl-next {
        right: -55px;
        position: absolute;
        background: #fafafa !important;
        box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%),
          0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        display: block;

        span {
          font-size: 35px;
          line-height: 0px;
          display: flex;
          align-items: center;
          margin-top: -8px;
        }

        &:hover {
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
  }
}

/*search page my added*/
.categories {
  padding-bottom: 20px;
  padding: 0rem 3rem 20px;

  input {
    width: 100% !important;
    border: 0px;
    outline: 0px;
    font-family: "Raleway";
    font-weight: bold;
    font-size: 1.75rem;
    color: rgba(0, 0, 0, 0.54) !important;
    color: #212427 !important;

    &:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.54) !important;
      color: #212427 !important;
    }

    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.54) !important;
      color: #212427 !important;
    }

    &:-moz-placeholder {
      color: rgba(0, 0, 0, 0.54) !important;
      color: #212427 !important;
    }

    /* firefox 19+ */
    &::-moz-placeholder {
      color: rgba(0, 0, 0, 0.54) !important;
      color: #212427 !important;
    }
  }

  .owl-carousel {
    display: flex;

    .owl-stage {
      display: flex;
      justify-content: center;
      .owl-item {
        border: 0px;
        width: 180px !important;

        button {
          width: 95%;
          outline: 0px;
          font-family: "Raleway";
          font-weight: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .owl-nav {
    button {
      &.owl-prev {
        left: -45px;
      }

      &.owl-next {
        right: -45px;
      }
    }
  }
}

@media (max-width: 767px) {
  .categories {
    padding: 0px 15px 20px;

    .user-pro .item {
      max-width: 48% !important;
      margin-right: 4%;
    }
  }
}

@media only screen and (max-width: 993px) and (min-width: 320px) {
  .categories {
    padding: 0px 40px 20px;

    owl-carousel .owl-nav {
      display: block !important;
      top: -3px;

      button {
        width: 25px !important;
        height: 25px !important;

        &.owl-prev {
          left: -35px;
        }

        &.owl-next {
          right: -35px;
        }
      }
    }
  }
}

/*caver page*/
.main-div {
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background: lightgray !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 12px 0px;
  }
}

/*about us page*/

.about-parallax-page {
  font-family: "Raleway";

  .mat-tab-label-active {
    color: #5ea1d8;
    position: relative;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #5ea1d8;
      position: absolute;
      bottom: -2px;
      transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
    }
  }

  .mat-tab-label {
    background: #fff;
    opacity: 1;
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 600;
    flex: 1;
    color: rgba(0, 0, 0, 0.54);
    color: #212427;
    // border-top:2px solid #70bfe2;
  }

  .mat-tab-label-container {
    overflow-x: hidden !important;
  }

  .mat-form-field {
    width: 100%;
  }

  .btn {
    margin: 0px auto;
    display: block;
    font-family: "Raleway";
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: lightgray;
  }

  .mat-checkbox-frame {
    border-color: #fff !important;
  }

  .mat-tab-body-content {
    overflow: hidden;
  }

  .mat-form-field-infix {
    border-bottom: 0px;
  }

  .mat-form-field-label {
    font-family: "Raleway";
    color: #fff !important;
  }

  .mat-checkbox-label {
    font-family: "Raleway";
  }

  .mat-form-field-underline {
    background-color: lightgrey;
  }

  .contact-form {
    .checkbox-section {
      .mat-checkbox.example-margin.mat-accent,
      #mat-checkbox-1 {
        float: left;
        width: 100%;

        .mat-checkbox-label {
          font-family: "Raleway";
          text-align: left;
          white-space: normal;
        }
      }
    }
  }

  .cdk-drop-list.example-list.cdk-drop-list-dragging {
    background: red;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: #5ea1d8 !important;
}

@media (min-width: 992px) {
  .about-tab {
    .mat-tab-header-pagination {
      display: none !important;
    }
  }
}

@media (max-width: 991px) {
  .about-tab {
    .mat-tab-header-pagination {
      background-color: #fafafa !important;
      display: flex !important;
      box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
        0 1px 18px 0 rgb(0 0 0 / 12%);
      width: 35px;
      height: 31px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
    }

    .mat-tab-header-pagination-before {
      left: 6px;
      top: 9px;
    }

    .mat-tab-header-pagination-after {
      right: 3px;
      top: 9px;
    }
  }
}

@media (max-width: 767px) {
  .abttop {
    display: flex;
    flex-direction: column;
    background: #fff;

    .profile {
      position: static;

      p {
        position: relative !important;
        right: 15px !important;
      }
    }
  }

  .about-tab .about-tab-section .mat-tab-label {
    // flex:none;
  }

  .about-tab .about-tab-section .mat-tab-label {
    flex: unset;
    // min-width:250px;
  }
}

/*  Followers page tab */
.followers {
  .mat-tab-label-container {
    overflow: hidden !important;
  }

  .mat-tab-labels {
    display: flex;
    justify-content: center;
  }

  .mat-tab-label-active {
    color: #5ea1d8;
    position: relative;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #5ea1d8;
      position: absolute;
      bottom: -2px;
      transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #5ea1d8;
    }
  }

  .mat-tab-label {
    background: #fff;
    opacity: 1;
    flex: 1;
    // border-top:2px solid #70bfe2;
  }

  .mat-ink-bar {
    opacity: 0;
  }
}

.followers {
  .mat-tab-label .mat-tab-label-content {
    font-size: 16px;
    font-family: "Raleway";
  }

  .mat-badge-medium.mat-badge-after .mat-badge-content {
    color: #fff !important;
  }
}

/* End  Followers page tab */

/*create flow*/
.publish {
  span {
    color: #fff !important;
  }
}

/*items required*/
.itemrequ {
  span {
    color: #fff !important;
  }
}

.dialogueclose {
  position: relative;
  top: -46px;
  right: -33px;
  float: right !important;
  outline: none;
}

/*comment page*/
.mat-dialog-container {
  &::-webkit-scrollbar {
    width: 4px;
    height: 100px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5ea1d8;
    outline: 1px solid #5ea1d8;
  }
}

.commentpage {
  h6 {
    font-size: 17px !important;
    font-weight: 600 !important;
  }

  input {
    outline: none !important;
  }

  .item.ng-star-inserted {
    display: flex;
    align-items: center;

    span {
      margin: 0px !important;
      color: rgba(0, 0, 0, 0.54) !important;
      color: #212427 !important;

      &:first-child {
        flex: 1;
      }

      &:nth-child(2) {
        flex: 2;
      }

      &:last-child {
        flex: 2;
      }
    }
  }

  .fixed-header {
    p {
      font-size: 17px !important;
      font-weight: 600 !important;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .fixed-header + div {
    &::-webkit-scrollbar {
      width: 4px;
      height: 100px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #5ea1d8;
      outline: 1px solid #5ea1d8;
    }

    button {
      span {
        color: #5ea1d8 !important;
      }
    }

    .mat-card {
      box-shadow: none;
      border-bottom: 1px solid lightgrey;
      border-radius: 0px;

      br {
        display: none;
      }
    }
  }
}

.flowdraft-wrap {
  overflow-y: inherit !important;

  .mat-mdc-tab-header {
    position: sticky;
    top: 0px;
    z-index: 10;
  }
}

@media screen and (min-width: 992px) {
  .example-stepList.cdk-drop-list-dragging {
    padding: 15px;
    overflow: hidden !important;
    background: #fff !important;
    -webkit-tap-highlight-color: #fff !important;

    // transform: none !important;
    .btn-delate-edit {
      text-align: left;
      margin: 15px 0px;
      display: flex;
      justify-content: space-between;
    }

    .btn-primary {
      //  background-color: #000 !important;
      background-color: #5ea1d8;
      margin-right: 10px;
      cursor: pointer;
      color: white;
      border: none;
    }

    img {
      max-height: 300px !important;
      height: 100% !important;
      position: relative;
      overflow: hidden;
      min-height: 300px !important;
      display: block;
      align-items: center;
      margin: 0px auto;
    }

    video {
      max-width: 100%;
      width: 90%;
      min-height: 250px;
      max-height: 250px;
      margin-top: 40px;
    }

    .s-num {
      display: block;
      text-align: center;
    }
  }

  /* this is for stick sub header */
  .row.userico {
    position: fixed !important;
    top: 80px !important;
    width: 27% !important;
    height: calc(100vh - 80px) !important;
    z-index: 10;
    position: fixed !important;
    top: 100px !important;
    width: 27% !important;
    height: calc(100vh - 0px) !important;
    z-index: 10;
    overflow: scroll !important;
  }

  .gridgroup-wrap {
    width: 100%;
    max-width: 100%;
    padding-left: 27%;
  }

  .mat-tab-header {
    position: fixed !important;
    top: 80px !important;
    width: 73.6%;
    right: 0;
    text-align: center;
  }

  .mat-tab-body-wrapper {
    padding-top: 80px;
  }

  .fix-head {
    position: fixed;
  }

  /* End  stick sub header */
}

@media screen and (max-width: 991px) {
  .example-stepList.cdk-drop-list-dragging {
    padding: 30px !important;

    overflow: hidden !important;
    background: #fff !important;
    -webkit-tap-highlight-color: #fff !important;

    .btn-delate-edit {
      text-align: left;
      margin: 15px 0px;
      display: flex;
      justify-content: space-between;
    }

    .btn-primary {
      //  background-color: #000 !important;
      background-color: #5ea1d8;
      margin-right: 10px;
      cursor: pointer;
      color: white;
      border: none;
    }

    img {
      max-height: 150px;
      height: 100%;
      position: relative;
      overflow: hidden;
      min-height: 150px;
      display: block;
      align-items: center;
      margin: 0px auto;
    }

    video {
      width: 100% !important;
      margin-top: 0px;
      max-height: 100% !important;
    }

    .img-and-video-sec {
      width: 100%;
    }

    .s-num {
      display: block;
      text-align: center;
      margin-bottom: 5px;
    }
  }
}
.createflow {
  .spinner-global {
    position: relative !important;
    top: 0px !important;
  }
}

.spinner-global {
  width: 100px;
  height: 100px;
  display: block;
  margin: 0px auto;
  position: absolute;
  // bottom: 0px;
  z-index: 100;
  left: 0px;
  right: 0px;
  top: 50vh;
}

#st-el-1 {
  display: none !important;
}

.dialoguebox {
  max-width: 600px !important;
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 20px 40px !important;
  width: 90%;
  max-width: 600px;
  width: 100%;
  position: relative;
  padding: 0;
  box-shadow: 0 30px 60px 0 rgb(0 0 0 / 30%);
  text-align: center;
  border: 0.5px solid #d3d3d3;

  button {
    outline: none !important;
  }
}

@media (max-width: 767px) {
  .sample-add {
    .owl-carousel.owl-drag .owl-item {
      width: 27vw !important;
    }
  }
}

@media (max-width: 460px) {
  .sample-add {
    .owl-carousel.owl-drag .owl-item {
      width: 42vw !important;
      margin-right: 10px !important;
    }
  }
}

.flowdraft-wrap {
  .mat-mdc-tab-list {
    // transform: none !important;
  }
}

/* Dark mode css starts from here*/

* {
  .darkmode {
    filter: invert(1) !important;

    .dialoguebox {
      filter: none !important;
      background: #000;
      position: absolute;
      top: 50%;
      // left:50%;
      // transform: translate(-50%, -50%);
      z-index: 1000;
    }

    color: #4e3535 !important;

    .cdk-overlay-pane {
      // filter:invert(1) !important;
      // background: #000 !important;
    }

    mat-icon {
      color: #fff !important;

      filter: invert(1) !important;
    }

    .fixed-header .mat-icon {
      filter: invert(1) !important;
      color: #4e3535 !important;
    }

    mat-sidenav-content {
      filter: invert(1) !important;
      color: #4e3535 !important;
    }

    & > * {
      filter: invert(1) !important;
      color: #4e3535 !important;
    }

    .mat-drawer-container {
      filter: invert(1) !important;
      color: #4e3535 !important;

      img {
        filter: invert(1) !important;
      }
    }

    .fixed-header {
      background: #000 !important;
      filter: invert(1) !important;
      color: #4e3535 !important;
    }

    & > div {
      filter: invert(1) !important;
      color: #4e3535 !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    a {
      color: #fff !important;
      color: #4e3535 !important;
    }

    video {
      filter: invert(1) !important;
    }

    // img{
    //   filter:invert(1) !important;
    // }
    .noti-menu,
    .mat-menu-panel {
      filter: invert(1) !important;

      // background: #000 !important;
    }

    .step-sec .img-wrap img {
      filter: invert(1);
    }

    .cate-wrapper figure {
      filter: invert(1) !important;
    }

    .main-div img,
    .item-table img,
    .partOfBundle img,
    .c-flow .inner-box .img-section-1 img.img-display {
      filter: invert(1) !important;
    }

    .ico-details .profilepic .profinner > div:last-child span .mat-icon {
      filter: invert(0) !important;
    }

    // .dialoguebox{
    //   filter:invert(1) !important;
    //   z-index:100;
    //   position: absolute;
    //   top:50%;
    //   left:50%;
    //   transform: translate(-50%, -50%);
    // }
    .ico-details .profilepic img {
      filter: invert(1) !important;
    }

    .colorRed mat-icon {
      color: red !important;
    }

    .mat-dialog-container {
      filter: invert(1);

      a {
        img {
          filter: invert(1) !important;
        }
      }
    }

    .about-parallax-page section .img-div-left img {
      filter: invert(1);
    }

    // .example-container{
    //   height:89vh !important;

    // }
    .flowbycat {
      //  height: 88.9vh !important;

      .Login {
        height: 100%;

        & > * {
          height: 100%;
        }
      }
    }

    // .row.userico{
    //   position: fixed !important;
    //   top: 0px !important;
    //   width: 27% !important;
    //   height: 88.9vh !important;
    //   z-index: 10;
    // }
    // .flowdraft-wrap{
    //   .mat-tab-header{
    //     top:0px !important;

    //   }
    // }
    .about-parallax-page section.section-6 img {
      filter: invert(1) !important;
    }

    .section-1 {
      filter: invert(1);
    }

    @media (max-width: 767px) {
      .fixed-header > span:first-child {
        filter: none !important;
        background: transparent !important;
      }
    }

    @media (max-width: 767px) {
      .fixed-header > span:first-child {
        filter: none !important;
        background: transparent !important;
      }
    }

    @media (min-width: 993px) {
      app-user-profile {
        .userico {
          position: fixed !important;
          top: 0px !important;
          width: 27% !important;
          height: 88.9vh !important;
          z-index: 10;
        }

        .mat-tab-header {
          top: 0px !important;
        }

        .example-container {
          max-height: 88.9vh !important;
        }

        .gridgroup-wrap {
          overflow-y: scroll !important;
          overflow-x: hidden !important;
          margin-right: -16px !important;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    .masonary-parent {
      img {
        filter: invert(1);
      }
    }

    .foot-ico ul li {
      span {
        filter: invert(1);
      }

      &:last-child {
        img {
          filter: invert(1);
        }
      }
    }

    .noti-menu span img {
      filter: invert(1);
    }

    .div1 {
      position: sticky !important;
    }

    .ng-trigger-dialogContainer {
      filter: none !important;
    }

    .vp-wrapper {
      img {
        filter: invert(1) !important;
      }
    }
  }
}

.artical-popup {
  max-height: 80vh;
  padding: 40px;

  h2 {
    padding-top: 20px;
  }

  img {
    width: 100% !important;
    object-fit: cover;
  }

  .mat-dialog-container {
    position: relative;
  }

  br {
    display: none;
  }

  mat-icon {
    background: transparent !important;
    color: #000 !important;
    color: #212427 !important;
  }

  button {
    position: absolute;
    top: 10px;
    right: 10px;
    // z-index:10;
    border: 0px;
    font-size: 16px;
    background: transparent !important;
    box-shadow: none !important;
    visibility: hidden;

    &:focus {
      border: 0px;
      outline: 0px;
    }

    mat-icon {
      position: relative;
      top: -18px;
      right: -18px;
      visibility: initial;
    }
  }
}

@media (max-width: 767px) {
  .artical-popup h2 {
    font-size: 1.5rem !important;
  }

  .mobileonly {
    order: 3;
  }

  .companyName {
    text-align: center;
  }
}

@media (min-width: 320px) {
  .artical-popup h2 {
    font-size: 1.5rem !important;
  }

  .artical-popup {
    max-width: 100vw !important;
    max-height: 100vh !important;
    height: 100%;
    padding: 10px !important;

    mat-icon {
      position: absolute !important;
      top: 2px !important;
      right: 0px;
    }

    .mat-dialog-container {
      overflow: hidden !important;
    }

    .showArticle {
      width: 100% !important;
      height: 86vh !important;
      overflow-y: auto !important;
    }
  }
}

video[poster] {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  padding-bottom: 0px !important;
  // max-height: 150px !important;
}

img {
  border: 0px !important;
}

@media screen and (min-width: 992px) {
  .vi-pro {
    .mat-tab-body-wrapper {
      padding-top: 80px !important;
    }

    .mat-drawer-content {
      padding: 0px !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .vi-pro {
    .mat-drawer-content {
      padding: 0px !important;
    }
  }
}

/*price icon design*/
.priceIcon-p {
  position: absolute;
  min-width: 30px;
  // aspect-ratio: 1/1;
  background: #5ea1d8;
  border-radius: 20px;
  top: 2px;
  right: 0px;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 2px rgb(186 183 183 / 40%);
  padding: 3px 5px !important;
  width: auto !important;
  max-width: unset !important;

  .font16 {
    font-size: 10px !important;
    color: #fff !important;
  }

  &.priceIcon-wp {
    // position: relative;
  }
}

/*new design*/
.voucher {
  .mat-form-field-type-mat-select:not(.mat-form-field-disabled)
    .mat-form-field-flex {
    background: transparent !important;
    border-bottom: 1px solid lightgray !important;
  }

  .mat-form-field-type-mat-input,
  .mat-form-field-type-mat-date-range-input {
    .mat-form-field-flex {
      background: transparent !important;
      border-bottom: 1px solid lightgray !important;
    }

    .mat-form-field-wrapper {
      padding-bottom: 14px !important;
    }
  }

  .mat-form-field-type-mat-date-range-input .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }

  .mat-form-field-type-mat-input {
    .mat-form-field-infix {
      border-top: 0px !important;
    }
  }

  .main-div .mat-form-field-appearance-legacy .mat-form-field-infix {
    border-top: 0px !important;
  }

  .mat-form-field-underline {
    display: none !important;
  }

  .mat-form-field-flex {
    padding: 0px !important;
  }
}

.example-form {
  svg {
    font-size: 30px;
  }
}

@media (max-width: 560px) {
  .flowdraft-wrap {
    .mat-tab-labels > div {
      min-width: 105px !important;

      &:last-child {
        min-width: 130px !important;
      }
    }

    .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
      display: none;
    }

    .mat-tab-header {
      overflow-x: auto;

      .mat-tab-labels {
      }
    }
  }
}

/*styleing 2023*/
.mat-dialog-container {
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%),
    0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-slide-toggle#mat-mdc-slide-toggle-1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

label#mat-mdc-slide-toggle-1-label {
  margin: 0px;
  font-weight: normal;
}

.mdc-button--raised {
  width: 120px;
  font-size: 16px;
  margin-top: 20px;
  background: blue;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  padding: 24px;
}

.with-icon {
  color: #212427;
  font-weight: 500;
  height: 53px;

  span {
    margin-right: 5px;
    color: #212427 !important;
  }
}

/*about us page*/
.about-parallax-page {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: transparent !important;
  }

  .mat-mdc-form-field-input-control::placeholder {
    color: #fff !important;
  }

  mat-form-field {
    width: 100%;
  }

  .mat-mdc-checkbox label {
    color: #fff;
  }

  .mdc-checkbox__background {
    border-color: #fff !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    border-top: 1px solid #fff !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
      .mdc-text-field--textarea
    )
    .mat-mdc-form-field-infix {
    padding: 0px;
    height: 40px;
  }

  .mat-mdc-text-field-wrapper {
    height: 40px;
  }
}

.gridgroup-wrap {
  .flowdraft-wrap {
    .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
      > .mat-mdc-tab-header
      .mat-mdc-tab {
      flex: auto !important;
      min-width: 200px !important;
    }
  }
}

.cdk-overlay-pane {
  width: max-content !important;
  height: auto !important;
}

.mat-mdc-dialog-actions {
  justify-content: center !important;

  h2 {
    text-align: center;
  }
}

.mat-mdc-dialog-container .mdc-dialog__title {
  text-align: center;
  display: flex;
  justify-content: center;
}

// .searchcarousel .owl-item:first-child .button1{
//   color:#fff !important;
// }

#formContent {
  .mdc-button {
    max-width: 50px;
    width: 50px;
    min-width: unset;
  }

  .mat-mdc-card {
    box-shadow: none;
    // border-bottom:1px solid #ddd;
  }

  .mat-mdc-card-content:first-child {
    padding: 0px !important;
  }
}

@media (max-width: 767px) {
  #formContent {
    .mat-mdc-button {
      max-width: 50px;
      width: 50px;
      min-width: unset;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

#formContent .button-section {
  display: flex;

  .mdc-button {
    width: 20% !important;
  }
}

.cover-page-wrapper {
  .mat-mdc-tab-group {
    width: 100%;

    .example-form {
      // padding:0px;
      input {
        height: 100px !important;
      }

      .file-wrapper {
        display: flex;
        display: flex;
        position: absolute;

        top: 0px;
        left: calc(100% - 130px);
        margin-top: 6px;

        .mdc-text-field__input {
          margin-top: 30px;
        }
      }

      .fileExplorer {
        margin-left: 0px !important;
        margin-right: 10px;
      }
    }

    .mat-mdc-form-field-infix.ng-tns-c130-5 {
      display: flex;

      input + div {
        display: flex;
      }
    }

    .mat-mdc-tab-labels {
      padding: 0px 16px;
    }

    .mdc-tab--active {
      background: whitesmoke;
    }

    .mat-mdc-tab-header .mdc-tab {
      .mat-badge.mat-badge {
        color: #212427 !important;
      }
    }
  }
}

/* for drop menu add class*/
.cdk-overlay-pane {
  .mat-mdc-menu-panel {
    .mat-mdc-menu-content {
      margin: 0px 10px 0px 0px;
      padding: 0px;

      button {
        padding: 5px 32px 5px 5px !important;
        background: transparent !important;

        &:hover {
          background: #5ea1d8 !important;
        }
      }
    }

    .mat-mdc-menu-item.mdc-list-item {
      margin: 5px;
    }
  }
}

.dialoguebox {
  padding: 0px !important;
  background: transparent !important;

  .cdk-global-overlay-wrapper {
    z-index: auto !important;
  }

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 0px !important;
    background: transparent !important;
    overflow-x: hidden;
  }
}

.cdk-global-overlay-wrapper {
  z-index: 1000 !important;
}

span.mdc-list-item__content {
  text-align: left !important;
}

.priceIcon-p .font16 {
  font-size: 10px !important;
}

.contact-form {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: #fff !important;
  }
}

.button-section {
  .newbuttondesigncreateflow {
    .mdc-button .mdc-button__label {
      color: #5ea1d8 !important;
    }
  }

  .publish span {
    color: #5ea1d8 !important;
  }

  .publish.newbuttondesigncreateflow1 {
    width: 120px !important;

    span {
      color: #fff !important;
    }
  }
}

/*home page menu design*/
.right-nav {
  img.material-icons {
    width: 30px !important;
    aspect-ratio: 1/1;
    margin-right: 0px;
  }

  .material-icons {
    width: 70px !important;
    flex-wrap: wrap;
    height: auto !important;
    justify-content: center;

    &:last-child {
      overflow: inherit;
    }

    // &:nth-last-child(2){
    //   width:30px !important;
    // }

    .icon-text {
      font-family: "Raleway" !important;
      display: block;
      width: 100%;
      font-size: 12px !important;
      text-align: center;
    }

    &:last-of-type {
      width: 40px !important;
      aspect-ratio: 1/1;
      margin-right: 0px;
    }
  }

  .icon-text {
    font-family: "Raleway" !important;
    display: block;
    width: 100%;
    font-size: 12px !important;
    text-align: center;
  }

  .user-ico-group {
    max-width: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .icon-text {
      font-family: "Raleway" !important;
      display: inline;
      line-height: 14px;
      width: 100%;
      font-size: 12px !important;
      text-align: center;
    }

    img {
      max-width: 30px !important;
    }
  }
}

.mobileonly {
  .mat-icon {
    overflow: inherit;
  }
}

.icon-text {
  font-family: "Raleway" !important;
  display: block;
  width: 100%;
  font-size: 12px !important;
  text-align: center;
}

@media (min-width: 1024px) {
  .searchcarousel {
    .owl-nav {
      button {
        span {
          font-size: 30px !important;
        }
      }
    }
  }
}

.itempage-align {
  .formContent {
    .imgdiv {
      height: 56px;
    }

    .filediv {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .item-field {
      overflow: hidden;
    }

    button,
    button.itemrequ {
      width: 100px !important;
      padding: 0px !important;
      height: 36px !important;
    }
  }
}

/*analytic popup*/

.dialogueboxnew {
  max-width: 600px !important;
  width: 100% !important;
}

.dashbored-wrap {
  .mat-mdc-tab-list {
    margin-bottom: 10px;
  }
}

.searchcarousel {
  .owl-stage {
    margin: 0px auto;
  }
}

.commonbuttton {
  font-family: Raleway !important;
  cursor: pointer !important;
  text-align: center !important;
  padding: 4px 20px !important;
  border-radius: 0.25rem !important;
  border: 1px solid #5ea1d8 !important;
  text-decoration: none !important;
  background-color: #5ea1d8 !important;
  color: #fff !important;
  height: 40px !important;
  outline: none !important;
}

/* new implementation starts from here 29-02-2024*/
#google_translate_element {
  text-align: right;
  position: fixed;
  right: 3rem;
  z-index: 1000;
  padding-top: 10px;
}

@media (max-width: 767px) {
  #google_translate_element {
    right: 15px;
  }
}

.fixed-header {
  height: 120px !important;
  align-items: flex-end !important;
  padding-bottom: 15px !important;
}

.about-parallax-page section.section-1 .pra-content p {
  font-size: 60px !important;
  line-height: 93px !important;
  background: -webkit-linear-gradient(#eee, rgb(25, 139, 173));
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-weight: 700 !important;
}

.about-parallax-page section.sectionextra p {
  font-size: 50px !important;
  font-style: italic;
  font-weight: 700 !important;
  color: #686f6f !important;
  color: #212427 !important;
  text-shadow: 2px 2px #ebebeb;
}

.about-parallax-page section.section-3 .card {
  &:hover {
    .card-text {
      p {
        color: #fff !important;
      }
    }
  }
}

.about-parallax-page section.section-6 p {
  font-size: 20px !important;
  color: #fff !important;
}

@media (max-width: 992px) {
  .about-parallax-page section.section-1 .pra-content p {
    font-size: inherit !important;
    line-height: inherit !important;
  }

  .about-parallax-page section.sectionextra p {
    font-size: 36px !important;
    line-height: 1.2 !important;
  }

  .three-col,
  .resource-hub,
  .common-overlap {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .resource-hub {
    .image-sec {
      img {
        height: 400px;
        object-fit: cover;
        width: 100%;
      }
    }

    .text-wrap {
      width: calc(100% - 30px) !important;
    }

    .blog-tile {
      margin-top: 10px !important;
    }
  }

  .mbotom {
    margin-bottom: 10px !important;
  }

  .with-icon span {
    word-break: break-all;
  }
}
.tabs-container,
.mat-mdc-tab-group {
  .mat-badge-content {
    border: 2px solid #fff;
    border-radius: 20px;
    min-width: 30px;
    width: auto !important;
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 5px !important;
    line-height: inherit !important;
    right: -31px !important;
    font-size: 10px !important;
    box-shadow: 0px 2px 2px rgba(186, 183, 183, 0.4);
  }
}
.mat-grid-tile {
  .mat-badge-content {
    border: 2px solid #fff;
    border-radius: 20px;
    min-width: 30px;
    width: auto !important;
    height: 19px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 5px !important;
    line-height: 10px !important;
    right: -31px !important;
    font-size: 10px !important;
    box-shadow: 0px 2px 2px rgba(186, 183, 183, 0.4);
    padding-top: 6px !important;
  }
}
.main-parent-div {
  background: #f9f9f9;
  padding: 30px 0px;
  min-height: calc(100vh - 70px);
  position: relative;
  top: 100px;
  padding-top: 0px;

  .form-parent {
    background: transparent;
    max-width: 700px;
    margin: 0px auto;

    padding: 0px;

    form {
      max-width: 700px;
      margin: 0 auto 20px !important;
      padding: 15px;
      background-color: #fff;
      border: 3px solid #e0e0e0;
      border: 3px solid #cdd8e8;
      border-bottom: 3px solid #985ce1;

      quill-editor {
        width: 100%;
        margin-bottom: 25px;
      }

      &:hover {
        border: 3px solid #985ce1;
      }

      & > form {
        width: 100%;
        border: 0px;
        margin-bottom: 0px !important;
        padding: 0px !important;

        &:hover {
          border: 0px;
        }

        & > * {
          width: 100%;
        }
      }

      .form-row {
        margin: 0px;

        .title-text {
          color: #0056b3;
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 10px !important;
          flex: 1;
          text-align: left;
          padding-right: 24px;

          & + mat-icon {
            color: #985ce1 !important;
          }
        }
      }

      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background: #fff;
      }

      .form-field-add {
        margin: 0px 0px;
        flex: unset;
      }

      table {
        th {
          font-weight: 400;
          color: #0056b3;
        }
      }

      & > :last-child {
        margin-bottom: 0px !important;
      }
    }

    .browser-element {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0px;
      flex-wrap: wrap;

      mat-label {
        margin-bottom: 0px;
      }

      img,
      video {
        max-width: 100%;
        min-width: 100%;
        max-height: 300px;
        display: block;
        object-fit: contain;
      }

      & + video {
        max-width: 100%;

        max-height: 300px;
        display: block;
        object-fit: contain;
        margin: 0px auto;
      }
    }
  }

  .form-group {
    width: 100%;

    &:last-child {
      margin-bottom: 0px;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }

    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }

  /*delete icon design*/
  .cdk-drag.ng-star-inserted {
    position: relative;

    .delete-ico {
      position: absolute;
      top: 37px;
      right: 10px;
    }

    mat-icon {
      color: #000;
    }
  }

  mat-form-field {
    width: 100%;

    /* Style the underline color when focused */
    &.mat-focused .mat-form-field-underline {
      background-color: #007bff;
      /* Change to your desired color */
    }

    /* Style the floating label color when focused */
    &.mat-focused .mat-form-field-label {
      color: #007bff;
      /* Change to your desired color */
    }
  }
}

.contact {
  quill-editor {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
    background: #fff;
    &:hover {
      border: 1px solid #985ce1 !important;
    }
  }
}
.quill-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  margin: 20px 0px;
  h3 {
    text-align: left;
  }
  .form-group {
    margin: 0px !important;
    padding: 0px 15px;
  }
}
.itempage-align {
  quill-editor {
    width: 100%;
    display: block;
    & > span {
      display: flex !important;
      width: auto !important;
      // &:first-child{
      //   flex:1;
      // }
      button {
        width: auto !important;
      }
    }
    .ql-formats {
      display: flex !important;
      justify-content: space-between !important;
    }
  }
  .formContent button,
  .itempage-align .formContent button.itemrequ {
    width: auto !important;
  }
  .ql-snow.ql-toolbar button svg,
  .ql-snow .ql-toolbar button svg {
    width: 20px !important;
  }
  .ql-toolbar.ql-snow {
    display: flex;
    width: 100%;
    & > span:first-child {
      flex: 1;
      margin-right: 0px !important;
    }
  }
}

.detail-description {
  background: #fff;
  .field-wrapper {
    display: flex;
    width: 100%;
    mat-form-field {
      flex: 1;
      margin-right: 20px;
      min-width: 22%;
      &:last-child {
        margin-right: 0px;
      }
      
    }
    .meeting {
      flex: 1;
      margin-right: 20px;
      min-width: 22%;
      mat-form-field {
        flex: unset;
        margin-right: 0px;
        min-width: 100%;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .mat-mdc-button-touch-target {
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .button-container {
    margin-left: 30px;
  }
}
.container5 {
  padding: 2rem 3rem 20px;
  background: #fff;
  label {
    margin-right: 10px;
  }
  .filter-section {
    margin-bottom: 10px;
  }
  p.btn.btn-primary {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
  #filter,#dateFilter{
    max-width: 400px;
    width: 100%;
    height: 40px;
  }
}
#ConsultationTimeZone, #slotTimes{
  max-width: 100%;
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    border:1px solid lightgray;
    border-radius: 5px;
}
.fixheaderinuserdashboard{
  position: fixed !important;
}
@media(max-width:767px){
  .container5{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.description-wrapper{
  .mat-expansion-panel-header{
    &.mat-expanded{
      background: gray !important;
      margin-bottom: 10px;
      color: #fff;
      .mat-expansion-panel-header-title{
        color: #fff !important;
      }
    }
    &:hover{
      background: gray !important;
      
      color: #fff;
      .mat-expansion-panel-header-title{
        color: #fff !important;
      }
    }
  }
}

// .like-list{
//   .mdc-form-field{
//     display: flex;
//     flex-direction: column;
//   }
//   .mat-accent{
//     justify-content: flex-start !important;
//   }
// }
.fix-head {
  position: fixed !important;
}
.categories .serach-bar{
  .mat-mdc-form-field-subscript-wrapper{
    display: none;
  }
  .mat-mdc-form-field-infix{
    min-height: unset;
  }
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .mat-mdc-form-field-infix{
    width: auto;
  }
}
@media(max-width:767px){
  .filter-btn{
    .mat-mdc-form-field{
      width:100%
    }
  }
}
